@import './variables';

.btn-square {
  border-radius: 0 !important;
  min-height: 40px;
}

.btn-round {
  border-radius: 1.2rem;
  min-height: 35px;
}

/* Modals */
app-custom-kendo-dialog .k-window-content, app-custom-kendo-dialog .k-prompt-container {
  padding: 0rem;
  background-color: $app-primary-lightest;
  overflow: hidden;
}

app-custom-confirm-dialog .k-window-content{
  background-color: $app-colour-white !important;
}

/* Tabs */
.nav-link, .nav-item {
  color: $app-secondary-light;
}

.nav-link.active, .nav-item.active, .nav-tabs .nav-link.active {
  color: $app-secondary-dark;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  border-color: transparent;
  height: 40px;
  display: flex;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-items: center;
  border-bottom: 2px solid $app-secondary-dark;
}

.nav-tabs {
  display: flex;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-items: center;
  border: 0;
  border-bottom: 1px solid $app-terciary-lightest;
  background-color: $app-colour-white !important;
}

/* Cards */
app-sign-card:hover {
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    color: $app-colour-white;
  }

  .form-check .form-check-input:disabled:checked ~ .form-check-label::before {
    background-color: $app-primary-light;
  }
}

.k-breadcrumb {
  background-color: transparent;
  color: $app-primary;
  height: $breadcrumb-height;
}

.is-readOnly .k-icon {
  display: none;
}

.k-dropdown .k-dropdown-wrap:hover, .k-dropdown .k-dropdown-wrap.k-state-hover {
  background-color: $app-primary;
  .k-icon {
    color: $app-colour-white;
  }
}

// Calendar

.k-calendar .k-state-selected .k-link {
  background-color: $app-primary-light;
  border-color: $app-primary-light;
  color: $app-colour-white !important;
}

.k-calendar .k-state-selected .k-link:hover {
  background-color: $app-primary-light;
  border-color: $app-primary-light;
}

.k-calendar .k-content .k-link, .k-calendar .k-calendar-content .k-link {
  border-radius: 1.25rem;
}

.k-calendar .k-content .k-today .k-link {
  box-shadow: none;
  font-weight: 1000;
  &.k-selected {
    color: white !important;
  }
}

// Tree
div.k-mid {
  display: flex;
  justify-items: center;
}

kendo-treeview {
  cursor: pointer;
}

.tr-card-custom {
  min-height: 130px !important;
}
