@import "./variables";

/*------------------------------------*\
    $FONTS
\*------------------------------------*/

@font-face {
    font-family: 'Futura Book';
    src: url('#{$font-path}FuturaBT-Book.eot');
    src: url('#{$font-path}FuturaBT-Book.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}FuturaBT-Book.woff2') format('woff2'),
        url('#{$font-path}FuturaBT-Book.woff') format('woff'),
        url('#{$font-path}FuturaBT-Book.ttf') format('truetype'),
        url('#{$font-path}FuturaBT-Book.svg#FuturaBT-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

