@import './_variables';



html, body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-size: 0.95rem;
  line-height: 1.2;
}

ul {
  list-style: none;
}

.section-container {
  background-color: $app-primary-lightest;
  padding: 2rem;
  height: calc(100vh - 140px);
  overflow-y: auto;
  width: 101%;
  overflow-x: hidden;
}


*:focus {
  outline: none;
}

fa-icon {
  cursor: pointer;
}

.icons {
  color: white;
  font-size: 1.3rem;
}

.tab-content {
  background-color: $app-primary-lightest;
}

/*IE11 Fallback*/
.app-content > *:not(router-outlet) {
    width: 99%;
}


/* Chrome, Safari and Opera syntax */
:-webkit-full-screen {
  background-color: white;
}

/* Firefox syntax */
:-moz-full-screen {
  background-color: white;
}

/* IE/Edge syntax */
:-ms-fullscreen {
  background-color: white;
}

/* Standard syntax */
:fullscreen {
  background-color: white;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: lighten($app-primary-dark, 30%);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($app-primary-dark, 20%);
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

#pdfViewer_sideBarToolbar {
  display: none !important;
}

.no-result {
  font-style: italic;
  color: $app-secondary-light;
}

.bell-icon {
  position: relative;
  top: 30px;
  left: -20px;
  font-size: 1.4rem;
  color: $app-primary;
}

$size: 12px;
.arrow-left {
  width: 0; 
  height: 0; 
  border-top: $size solid transparent;
  border-bottom: $size solid transparent; 
  border-right: $size solid white;
  position: relative;
  top: 30px;
  left: 2px;
}

signature-pad.white canvas {
  background-color: white;
}


signature-pad.color canvas {
  background-color: $app-primary-lightest;
}

.k-dateinput .k-dateinput-wrap {
  background: transparent;
}

.hidden {
  display: none;
}

.level-area {
  color:  $app-primary;
}

.level-unit {
  color: $app-secondary;
}

.level-element {
  color: $app-terciary;
}

.nav-item {
  cursor: pointer;
} 

.k-chip-solid-base {
  border-color: $app-primary;
  color: white;
  background-color: $app-primary;
}

.k-picker-solid, .k-picker-solid:hover, .k-picker-solid:focus, .k-picker-solid:focus-within {
  background-color: $app-primary;
  color: white;
}

.k-column-list-item:hover, .k-columnmenu-item:hover {
  background-color: $app-primary-lightest;
}

.bulk-update-modal {
  overflow-y: auto;
  height: calc(100vh - 180px);
}

.no-results {
  font-size: 1.2rem;
  font-style: italic;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-variant: small-caps;
  padding: 1rem;
}