@import './variables';

.form-check .form-check-input:disabled:checked ~ .form-check-label::before {
  background-color: $app-primary-dark;
}

.k-combobox {
  width: 100% !important;
}

.k-combobox .k-select, .k-combobox .k-select:hover, 
.k-combobox > :hover .k-select, .k-combobox .k-state-hover .k-select {
  border-color: transparent;
}

.disabled-template {
  color: $app-terciary-light;
}

.k-button-solid-base, .k-button-solid-base.k-selected {
  border-color: $app-primary;
  color: white;
  background-color:  $app-primary-light;
}

.k-checkbox {
  height: 20px;
  width: 20px;
}

button.btn-danger, button.btn-danger:hover {
  color: white !important;
}
