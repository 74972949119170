$font-path: "/assets/fonts/" !default;
$icon-path: "/assets/icon" !default;

$header-height: 80px;
$sidebar-width: 370px;
$footer-height: 55px;
$breadcrumb-height: 65px;
$size-arrow: 7px;

// Principal Colors
$app-primary-darkest: #0d2c5d;
$app-primary-dark: #497095;
$app-primary: #497095;
$app-primary-light: #3f7eae;
$app-primary-lightest: #f4f6fc;
$app-colour-white: #FFFFFF;
$app-sidebar-selected-color: #C6D9E7;

$app-secondary-darkest: #203b55;
$app-secondary-dark: #286290;
$app-secondary: #2a76b0;
$app-secondary-light: #9fb8d7;
$app-secondary-lightest: #dfe7f2;

$app-terciary-darkest: #414141;
$app-terciary-dark: #6d6d6d;
$app-terciary: #848484;
$app-terciary-light: #B8B8B8;
$app-terciary-lightest: #DDDDDD;

// colors
$app-blue:    #2a76b0;
$app-cyan:    #17a2b8;
$app-red:     #E56975;
$app-orange:  #F0AD4E;
$app-yellow:  #ffc107;
$app-green:   #5CB85C;

// Buttons
$app-button: $app-primary-darkest;
$app-button-secondary: $app-primary-light;

// // Alerts
$app-alert-warning: $app-orange;
$app-alert-warning-light: lighten($app-orange, 10%); // #FCF7E4;
$app-alert-info: $app-blue;
$app-alert-info-light: lighten($app-blue, 10%); //#DCEDF6;
$app-alert-error: $app-red;
$app-alert-error-light: lighten($app-red, 10%); //#EFDFDF;
$app-alert-success: $app-green;
$app-alert-success-light: lighten($app-green, 10%); //#E2EED8;


// // Letra
$app-font-color: $app-terciary-darkest;
$app-front-color-light: $app-terciary;


// Cards
$tr-card-min-height: 70px !default;
$tr-card-min-width: 200px !default;
$tr-card-padding: 1rem !default;
$tr-card-border: 1px solid $app-terciary-lightest !default;
$tr-card-border-radius: 5px !default;
$tr-card-box-shadow: 0 4px 10px 0 $app-terciary-lightest !default;
$tr-card-backgroud: $app-colour-white !default;
$tr-card-background-hover: $app-primary !default;
$tr-card-font-color: $app-font-color !default;
$tr-card-font-color-hover: $app-colour-white !default;
$tr-card-margin: 0;
$tr-card-margin-top: 1rem;

// Icons
$app-icon-color: #007DB0;

// Other
$red-rejected: #f44336;