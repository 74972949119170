:root {
  --notification-badge-color: #E56975;
  --notification-bell-top: -10px;
  --notification-bell-right: 5px;
  --notification-bell-color: #E56975;
  --notification-badge-border-color: #E56975;
  --notification-badge-font-color: white;
  --notification-badge-size: 4px 8px;
  --notification-badge-font-size: 0.6rem;
  --notification-list-shadow: 6px 6px 11px -7px rgba(128,123,128,0.53);
  --bell-font-size: 1.5rem;
  --notifications-list-background: white;
  --bell-color:#2a76b0;
  --notifications-div-width: 300px;
  --notifications-div-width-center: -150px;
  --notification-list-border: 1px solid #DDDDDD;
  // Cards notifications
  --tr-card-min-height: 70px;
  --tr-card-min-width: 200px;
  --tr-card-padding: 1rem;
  --tr-card-border: 1px solid  #DDDDDD;
  --tr-card-border-radius: 5px;
  --tr-card-box-shadow: 0 4px 10px 0  #DDDDDD;
  --tr-card-backgroud: white;
  --tr-card-background-hover:  #497095;
  --tr-card-font-color: #414141;
  --tr-card-font-color-hover: white;
  --tr-card-margin: 0;
  --tr-card-margin-top: 1rem;
  --notification-list-height: calc(100vh - 180px);
  --tr-card-font-size: 1rem;
  --tr-card-check-color: #3c5d7b;
  --tr-card-title-color: #92aeca;
  --tr-card-mute-color: #B8B8B8;
  // Scroll
  --tr-color-dark: #497095;
}
