@import '../../dist/tr-notifications/src/lib/style/taski-theme.scss';
@import './variables';

:root {
  --bell-color: #3f7eae;
  --notification-bell-top: 12px;
  --notification-bell-right: 5px;
  // --notification-badge-font-color: white;
  // --bell-font-size: 1.5rem;
  // --notifications-list-background: white;
  // --bell-color:#2a76b0;
  // --notifications-div-width: 300px;
}
